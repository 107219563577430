<template>
  <section>
    <CreateBuildingForm />
  </section>
</template>

<script>
import CreateBuildingForm from "../components/CreateBuildingForm";
// import { validationMixin } from "vuelidate";
// import { required, email } from "vuelidate/lib/validators";
export default {
  name: "CreateBuilding",

  components: { CreateBuildingForm },
};
</script>

<style lang="scss" scoped></style>
